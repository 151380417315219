import React from 'react'
import UtilService from '../services/util'
import OpenNotification from './common/CommonButton/OpenNotification'
import FrontWrapper from '../components/FrontStatic/common/FrontWrapper'
import FrontFooter from '../components/FrontStatic/common/Front-Footer'
import IntlMessages from '../util/IntlMessages'
import { Modal } from 'antd'

let grp1 = [101, 102, 103, 104, 105]
let grp2 = [106, 107, 108, 109, 110]
let grp3 = [111, 112, 113, 114, 115]
let grp4 = [116, 117, 118, 119, 220]
let grp5 = [221, 222, 223, 224, 225]

class Feedback extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            values: {
                toggle_2: false,
                toggle_1: false,
                toggle_3: false,
                toggle_4: false,
                val3: '',
                pon: '',
                eon: ''
            },
            showEmailIp: false,
            showPhoneIp: false,
            success: false
        }
    }
    handleChange = e => {
        let obj = this.state.values
        if (e.target.name === 'my-radio1') {
            if (e.target.id === 'radio_1') this.setState({ showEmailIp: true, showPhoneIp: false })
            else if (e.target.id === 'radio_2') this.setState({ showEmailIp: false, showPhoneIp: true })
            else if (e.target.id === 'radio_3') this.setState({ showEmailIp: false, showPhoneIp: false })
        } else if (
            e.target.id === 'toggle_1' ||
            e.target.id === 'toggle_2' ||
            e.target.id === 'toggle_3' ||
            e.target.id === 'toggle_4'
        ) {
            obj[e.target.id] = !obj[e.target.id]
            if (obj[e.target.id]) {
                if (e.target.id === 'toggle_1') obj['toggle_2'] = false
                else if (e.target.id === 'toggle_2') obj['toggle_1'] = false
                else if (e.target.id === 'toggle_3') obj['toggle_4'] = false
                else if (e.target.id === 'toggle_4') obj['toggle_3'] = false
            }
        } else obj[e.target.id] = e.target.value
        this.setState({ values: obj })
    }

    submitForm = () => {
        let self = this
        let values = this.state.values
        let radio = {}
        for (let i = 0; i < 226; i++) {
            if (document.getElementById('radio_' + i) && document.getElementById('radio_' + i).checked) {
                if (i === 1) radio[document.getElementById('radio_' + i).name] = 'email'
                else if (i === 2) radio[document.getElementById('radio_' + i).name] = 'call'
                else if (i === 3) radio[document.getElementById('radio_' + i).name] = 'no'
                else if (i > 3 && i < 14) radio[document.getElementById('radio_' + i).name] = i - 3
                else if (grp1.includes(i)) radio[document.getElementById('radio_' + i).name] = 1
                else if (grp2.includes(i)) radio[document.getElementById('radio_' + i).name] = 2
                else if (grp3.includes(i)) radio[document.getElementById('radio_' + i).name] = 3
                else if (grp4.includes(i)) radio[document.getElementById('radio_' + i).name] = 4
                else if (grp5.includes(i)) radio[document.getElementById('radio_' + i).name] = 5
            }
        }
        let rqLayout = null
        if (values['toggle_1']) rqLayout = false
        else if (values['toggle_2']) rqLayout = true
        let rqVisual = null
        if (values['toggle_4']) rqVisual = false
        else if (values['toggle_3']) rqVisual = true
        let rqEmail = null
        let rqPhone = null
        if (radio['my-radio1'] === 'email') rqEmail = values.eon
        else if (radio['my-radio1'] === 'call') rqPhone = values.pon

        let obj = {
            method: 'post',
            url: 'admin/feedback/create',
            request: {
                layout: rqLayout,
                visualAppealing: rqVisual,
                howImprove: values.val3,
                contactedBy: radio['my-radio1'] || null,
                contactedByEmail: rqEmail,
                contactedByPhone: rqPhone,
                overallExperience: radio['my-radio2'] || null,
                recommend: radio['my-radio3'] || null,
                onlinePurchase: radio['my-radio4'] || null,
                searchingDiamonds: radio['my-radio5'] || null,
                packagingPrduct: radio['my-radio6'] || null,
                paymentFacility: radio['my-radio7'] || null
            }
        }
        let flag = false
        for (var k in obj.request) {
            if (obj.request[k]) {
                flag = true
            }
        }
        if (flag) {
            UtilService.callApi(obj, function(err, data) {
                if (data && data.code === 'OK') {
                    self.setState({ success: true })
                }
            })
        } else {
            OpenNotification({
                type: 'error',
                title: "Please fill the form."
            })
        }
        this.resetForm()
    }

    resetForm = () => {
        for (let i = 0; i < 226; i++) {
            if (document.getElementById('radio_' + i)) document.getElementById('radio_' + i).checked = false
        }
        let values = {
            toggle_2: false,
            toggle_1: false,
            toggle_3: false,
            toggle_4: false,
            val3: '',
            pon: '',
            eon: ''
        }
        this.setState({ values, showEmailIp: false, showPhoneIp: false })
    }

    getQuestion1 = () => {
        return (
            <div className='width-50 pr-30 pr-xs-0'>
                <div className='page-head mb-25'>
                    <h3>1. Does the layout looks User Friendly?</h3>
                </div>
                <div className='d-flex d-xs-block'>
                    <div className='form-group d-flex fdb-btn'>
                        <input
                            type='checkbox'
                            id='toggle_2'
                            className='chkbx-toggle'
                            checked={this.state.values.toggle_2}
                            onChange={this.handleChange}
                        />
                        <label className='feedback-label' htmlFor='toggle_2' />
                        <span className='toggle-label' onClick={() => document.getElementById('toggle_2').click()}>
                            Yes
                        </span>
                    </div>
                    <div className='form-group d-flex fdb-btn'>
                        <input
                            type='checkbox'
                            id='toggle_1'
                            className='chkbx-toggle'
                            checked={this.state.values.toggle_1}
                            onChange={this.handleChange}
                        />
                        <label className='feedback-label' htmlFor='toggle_1' />
                        <span className='toggle-label' onClick={() => document.getElementById('toggle_1').click()}>
                            No
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    getQuestion2 = () => {
        return (
            <div className='width-50'>
                <div className='page-head mb-25'>
                    <h3>2. Is our website visually appealing?</h3>
                </div>
                <div className='d-flex d-xs-block'>
                    <div className='form-group d-flex fdb-btn'>
                        <input
                            type='checkbox'
                            id='toggle_3'
                            className='chkbx-toggle'
                            checked={this.state.values.toggle_3}
                            onChange={this.handleChange}
                        />
                        <label className='feedback-label' htmlFor='toggle_3' />
                        <span className='toggle-label' onClick={() => document.getElementById('toggle_3').click()}>
                            Yes
                        </span>
                    </div>
                    <div className='form-group d-flex fdb-btn'>
                        <input
                            type='checkbox'
                            id='toggle_4'
                            className='chkbx-toggle'
                            checked={this.state.values.toggle_4}
                            onChange={this.handleChange}
                        />
                        <label className='feedback-label' htmlFor='toggle_4' />
                        <span className='toggle-label' onClick={() => document.getElementById('toggle_4').click()}>
                            No
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    getQuestion3 = () => {
        return (
            <div className='width-50 pr-30 pr-xs-0'>
                <div className='improve-detail'>
                    <div className='page-head'>
                        <h3>3. How can we improve hk.co?</h3>
                    </div>
                    <textarea
                        placeholder='Comment'
                        id='val3'
                        className='mb-50 mb-xs-0'
                        onChange={this.handleChange}
                        value={this.state.values.val3}
                    />
                </div>
            </div>
        )
    }

    getQuestion4 = () => {
        return (
            <div className='width-50'>
                <div className='page-head'>
                    <h3>
                        4. Would you like to be contacted by a Customer Service team member regarding your feedback?
                    </h3>
                </div>
                <div className='mb-50 feedback-form-width mb-xs-20' style={{ width: '50%' }}>
                    <div className='form-group mb-20 feedback-block'>
                        <input
                            className='custom-radio'
                            id='radio_1'
                            type='radio'
                            name='my-radio1'
                            onChange={this.handleChange}
                        />
                        <label className='feedback-label' htmlFor='radio_1'>
                            <span /> Yes , Please email me at
                        </label>
                        {this.state.showEmailIp && (
                            <div className='form-group' style={{ paddingLeft: '30px' }}>
                                <div className='form-control'>
                                    <input
                                        className='pincode-number'
                                        id='eon'
                                        type='email'
                                        onChange={this.handleChange}
                                        value={this.state.values.eon}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='form-group feedback-block'>
                        <input
                            className='custom-radio'
                            type='radio'
                            id='radio_2'
                            name='my-radio1'
                            onChange={this.handleChange}
                        />
                        <label className='feedback-label' htmlFor='radio_2'>
                            <span /> Yes , Please call me at
                        </label>
                        {this.state.showPhoneIp && (
                            <div className='form-group' style={{ paddingLeft: '30px' }}>
                                <div className='form-control'>
                                    <input
                                        className='pincode-number'
                                        type='number'
                                        id='pon'
                                        onChange={this.handleChange}
                                        value={this.state.values.pon}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='form-group feedback-block'>
                        <input
                            className='custom-radio'
                            type='radio'
                            id='radio_3'
                            name='my-radio1'
                            onChange={this.handleChange}
                        />
                        <label className='feedback-label' htmlFor='radio_3'>
                            <span /> No , Thank You
                        </label>
                    </div>
                </div>
            </div>
        )
    }

    getSadFace = () => {
        return (
            <svg
                version='1.1'
                id='Layer_1'
                xmlns='http://www.w3.org/2000/svg'
                xlink='http://www.w3.org/1999/xlink'
                x='0px'
                y='0px'
                viewBox='0 0 330 330'
                style={{ enableBackground: 'new 0 0 330 330' }}
                space='preserve'
            >
                <path
                    id='XMLID_23_'
                    d='M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165c90.982,0,165-74.019,165-165S255.982,0,165,0z M81,133 c0-5.26,2.14-10.42,5.859-14.141C90.58,115.14,95.73,113,101,113c5.26,0,10.42,2.14,14.14,5.859C118.869,122.58,121,127.74,121,133 c0,5.26-2.131,10.42-5.86,14.14c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C83.14,143.42,81,138.26,81,133z M226.518,226.518c-5.857,5.857-15.355,5.857-21.213,0c-10.767-10.766-25.08-16.694-40.305-16.694 c-15.227,0-29.539,5.929-40.306,16.694c-2.929,2.929-6.768,4.394-10.606,4.394s-7.678-1.465-10.607-4.394 c-5.858-5.857-5.858-15.355-0.001-21.213c16.432-16.432,38.281-25.482,61.519-25.482c0.001,0-0.001,0,0.001,0 c23.238,0,45.086,9.05,61.518,25.482C232.375,211.162,232.375,220.66,226.518,226.518z M243.14,147.14 c-3.72,3.72-8.88,5.86-14.14,5.86c-5.26,0-10.42-2.141-14.141-5.86C211.13,143.42,209,138.26,209,133 c0-5.26,2.13-10.42,5.859-14.141C218.58,115.14,223.73,113,229,113c5.26,0,10.42,2.14,14.14,5.859 c3.73,3.721,5.86,8.881,5.86,14.141C249,138.26,246.869,143.42,243.14,147.14z'
                />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
            </svg>
        )
    }

    getOkayFace = () => {
        return (
            <svg
                id='bold_v2'
                enableBackground='new 0 0 24 24'
                height='512'
                viewBox='0 0 24 24'
                width='512'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='m12 0c-6.62 0-12 5.38-12 12s5.38 12 12 12 12-5.38 12-12-5.38-12-12-12zm-7 8.5c0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5s-2.5-1.12-2.5-2.5zm10.25 9.75h-6.5c-.553 0-1-.448-1-1s.447-1 1-1h6.5c.553 0 1 .448 1 1s-.447 1-1 1zm1.25-7.25c-1.38 0-2.5-1.12-2.5-2.5 0-.41.34-.75.75-.75s.75.34.75.75c0 .55.45 1 1 1s1-.45 1-1c0-.41.34-.75.75-.75s.75.34.75.75c0 1.38-1.12 2.5-2.5 2.5z' />
            </svg>
        )
    }

    getHappyFace = () => {
        return (
            <svg
                version='1.1'
                id='Layer_1'
                xmlns='http://www.w3.org/2000/svg'
                xlink='http://www.w3.org/1999/xlink'
                x='0px'
                y='0px'
                viewBox='0 0 455 455'
                style={{ enableBackground: 'new 0 0 455 455' }}
                space='preserve'
            >
                <path d='M227.5,0C101.855,0,0,101.855,0,227.5S101.855,455,227.5,455S455,353.145,455,227.5S353.145,0,227.5,0z M303.514,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S284.184,146.486,303.514,146.486z M151.486,146.486 c19.33,0,35,15.67,35,35s-15.67,35-35,35c-19.33,0-35-15.67-35-35S132.156,146.486,151.486,146.486z M227.5,370 c-59.42,0-113.171-37.504-133.752-93.325l28.148-10.378C138.15,310.381,180.589,340,227.5,340s89.35-29.619,105.604-73.703 l28.148,10.378C340.671,332.496,286.92,370,227.5,370z' />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
                <g />
            </svg>
        )
    }

    getQuestion5 = () => {
        return (
            <div className='first_smly_part  mb-50 mb-xs-20'>
                <div className='icon_block'>
                    <div className='row_num'>1</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_4' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_4'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon' onClick={() => document.getElementById('radio_4').click()}>
                        {this.getSadFace()}
                    </div>
                </div>
                <div className='icon_block'>
                    <div className='row_num'>2</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_5' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_5'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon' onClick={() => document.getElementById('radio_5').click()}>
                        {this.getSadFace()}
                    </div>
                </div>
                <div className='icon_block'>
                    <div className='row_num'>3</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_6' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_6'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon' onClick={() => document.getElementById('radio_6').click()}>
                        {this.getSadFace()}
                    </div>
                </div>
                <div className='icon_block'>
                    <div className='row_num'>4</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_7' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_7'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon' onClick={() => document.getElementById('radio_7').click()}>
                        {this.getSadFace()}
                    </div>
                </div>
                <div className='icon_block'>
                    <div className='row_num'>5</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_8' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_8'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon' onClick={() => document.getElementById('radio_8').click()}>
                        {this.getSadFace()}
                    </div>
                </div>
                <div className='icon_block'>
                    <div className='row_num'>6</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_9' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_9'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon black_sml' onClick={() => document.getElementById('radio_9').click()}>
                        {this.getOkayFace()}
                    </div>
                </div>
                <div className='icon_block'>
                    <div className='row_num'>7</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_10' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_10'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon black_sml' onClick={() => document.getElementById('radio_10').click()}>
                        {this.getOkayFace()}
                    </div>
                </div>
                <div className='icon_block'>
                    <div className='row_num'>8</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_11' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_11'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon black_sml' onClick={() => document.getElementById('radio_11').click()}>
                        {this.getOkayFace()}
                    </div>
                </div>
                <div className='icon_block'>
                    <div className='row_num'>9</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_12' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_12'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon green_sml' onClick={() => document.getElementById('radio_12').click()}>
                        {this.getHappyFace()}
                    </div>
                </div>
                <div className='icon_block'>
                    <div className='row_num'>10</div>
                    <div className='radi_btn'>
                        <div>
                            <div className='form-group'>
                                <input className='custom-radio' id='radio_13' type='radio' name='my-radio2' />
                                <label className='feedback-label' htmlFor='radio_13'>
                                    <span />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='rate_icon green_sml' onClick={() => document.getElementById('radio_13').click()}>
                        {this.getHappyFace()}
                    </div>
                </div>
            </div>
        )
    }

    getRadio = (id, name, face) => {
        return (
            <>
                <div>
                    <div className='form-group'>
                        <input className='custom-radio' type='radio' id={id} name={name} value='1' />
                        <label className='feedback-label' htmlFor={id}>
                            <span />
                        </label>
                    </div>
                </div>
                <div
                    className={
                        'rate_icon mb-25' + (face === 'ok' ? ' black_sml' : '') + (face === 'happy' ? ' green_sml' : '')
                    }
                    onClick={() => document.getElementById(id).click()}
                >
                    {face === 'sad' ? this.getSadFace() : face === 'happy' ? this.getHappyFace() : this.getOkayFace()}
                </div>
            </>
        )
    }

    getRadioWithHeading = (n, id, name, disp = true) => {
        return (
            <>
                <div className={'rate_title mb-25' + ((n === '2' || n === '4') && disp ? ' height-block' : '')}>
                    {n === '1' ? 'Strongly Disagree' : n === '3' ? 'Neutral' : n === '5' ? 'Strongly Agree' : ''}
                </div>
                <div className='row_num' style={n === '2' || n === '4' ? { marginTop: '24px' } : {}}>
                    {n}
                </div>
                {disp && <div className='rate_title mb-25' />}
                <div className='radi_btn'>
                    <div>
                        <div className='form-group'>
                            <input className='custom-radio' type='radio' id={id} name={name} value='1' />
                            <label className='feedback-label' htmlFor={id}>
                                <span />
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    className={
                        'rate_icon mb-25' +
                        (['2', '3'].includes(n) ? ' black_sml' : '') +
                        (['4', '5'].includes(n) ? ' green_sml' : '')
                    }
                    onClick={() => document.getElementById(id).click()}
                >
                    {n === '1' ? this.getSadFace() : ['4', '5'].includes(n) ? this.getHappyFace() : this.getOkayFace()}
                </div>
            </>
        )
    }

    getIconBlock = (no, r1, r2, r3, r4, r5) => {
        let face = no === '1' ? 'sad' : ['4', '5'].includes(no) ? 'happy' : 'ok'
        return (
            <div className='icon_block'>
                {this.getRadioWithHeading(no, r1, 'my-radio3')}
                {this.getRadio(r2, 'my-radio4', face)}
                {this.getRadio(r3, 'my-radio5', face)}
                {this.getRadio(r4, 'my-radio6', face)}
                {this.getRadio(r5, 'my-radio7', face)}
            </div>
        )
    };

    getRightRatingPart = () => {
        if (typeof window !== "undefined" && window.screen.width < 992) return <></>
        return (
            <div className='right_rating_part'>
                {this.getIconBlock('1', 'radio_101', 'radio_102', 'radio_103', 'radio_104', 'radio_105')}
                {this.getIconBlock('2', 'radio_106', 'radio_107', 'radio_108', 'radio_109', 'radio_110')}
                {this.getIconBlock('3', 'radio_111', 'radio_112', 'radio_113', 'radio_114', 'radio_115')}
                {this.getIconBlock('4', 'radio_116', 'radio_117', 'radio_118', 'radio_119', 'radio_220')}
                {this.getIconBlock('5', 'radio_221', 'radio_222', 'radio_223', 'radio_224', 'radio_225')}
            </div>
        )
    };

    getMobileRadio1 = () => {
        if (typeof window !== "undefined" && window.screen.width >= 992) return <></>
        return (
            <div className='hidden-md-up'>
                <div className='icon_block'>{this.getRadioWithHeading('1', 'radio_101', 'my-radio3', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('2', 'radio_106', 'my-radio3', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('3', 'radio_111', 'my-radio3', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('4', 'radio_116', 'my-radio3', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('5', 'radio_221', 'my-radio3', false)}</div>
            </div>
        )
    }

    getMobileRadio2 = () => {
        if (typeof window !== "undefined" && window.screen.width >= 992) return <></>
        return (
            <div className='hidden-md-up'>
                <div className='icon_block'>{this.getRadioWithHeading('1', 'radio_102', 'my-radio4', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('2', 'radio_107', 'my-radio4', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('3', 'radio_112', 'my-radio4', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('4', 'radio_117', 'my-radio4', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('5', 'radio_222', 'my-radio4', false)}</div>
            </div>
        )
    }

    getMobileRadio3 = () => {
        if (typeof window !== "undefined" && window.screen.width >= 992) return <></>
        return (
            <div className='hidden-md-up'>
                <div className='icon_block'>{this.getRadioWithHeading('1', 'radio_103', 'my-radio5', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('2', 'radio_108', 'my-radio5', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('3', 'radio_113', 'my-radio5', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('4', 'radio_118', 'my-radio5', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('5', 'radio_223', 'my-radio5', false)}</div>
            </div>
        )
    }

    getMobileRadio4 = () => {
        if (typeof window !== "undefined" && window.screen.width >= 992) return <></>
        return (
            <div className='hidden-md-up'>
                <div className='icon_block'>{this.getRadioWithHeading('1', 'radio_104', 'my-radio6', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('2', 'radio_109', 'my-radio6', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('3', 'radio_114', 'my-radio6', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('4', 'radio_119', 'my-radio6', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('5', 'radio_224', 'my-radio6', false)}</div>
            </div>
        )
    }

    getMobileRadio5 = () => {
        if (typeof window !== "undefined" && window.screen.width >= 992) return <></>
        return (
            <div className='hidden-md-up'>
                <div className='icon_block'>{this.getRadioWithHeading('1', 'radio_105', 'my-radio7', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('2', 'radio_110', 'my-radio7', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('3', 'radio_115', 'my-radio7', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('4', 'radio_220', 'my-radio7', false)}</div>
                <div className='icon_block'>{this.getRadioWithHeading('5', 'radio_225', 'my-radio7', false)}</div>
            </div>
        )
    }

    render() {
        return (
            <FrontWrapper location={this.props.location} isForDevice={this.props.isForDevice}>
                <div className='scroll-content-wrapper' data-scrollbar>
                    <div className='page-main page-current'>
                        <div className='page-toload legacy-page' data-bodyclassname='legacy'>
                            <main className='page-content' role='main'>
                                <div
                                    className={
                                        this.props.isForDevice
                                            ? 'feedback-wrapper-info remove-space'
                                            : 'feedback-wrapper-info'
                                    }
                                >
                                    <div className='feedback-front-page'>
                                        <div className='page-head feedback-front-page-title mb-100 mb-xs-40'>
                                            <h3>{this.props.isForDevice ? '' : <IntlMessages id='app.Feedback' />}</h3>
                                        </div>
                                        <div className='feedback-box'>
                                            <div className='width-full'>
                                                <div className='d-flex mb-50 feedback-wrapper mb-xs-20'>
                                                    {this.getQuestion1()}
                                                    {this.getQuestion2()}
                                                </div>
                                            </div>
                                            <div className='width-full'>
                                                <div className='d-flex feedback-wrapper mb-tv-0'>
                                                    {this.getQuestion3()}
                                                    {this.getQuestion4()}
                                                </div>
                                            </div>
                                            <div className='main_icon_part'>
                                                <div className='page-head'>
                                                    <h3>5. Please rate your overall experience with hk.co.</h3>
                                                </div>
                                                {this.getQuestion5()}
                                                <div className='page-head ml-15'>
                                                    <h3>
                                                        * Please rate the following sentences from 1-5 [1 being the
                                                        worst and 5 being the best]
                                                    </h3>
                                                </div>
                                                <div className='give_rate_part mt-30'>
                                                    <div className='feedback-from-block'>
                                                        <div className='left_rating_part'>
                                                            <div className='page-head mb-75'>
                                                                <h3>6. You will recommend hk.co to a third person.</h3>
                                                                {this.getMobileRadio1()}
                                                            </div>
                                                            <div className='page-head mb-75'>
                                                                <h3>7. Your online purchase is very user friendly.</h3>
                                                                {this.getMobileRadio2()}
                                                            </div>
                                                            <div className='page-head mb-75'>
                                                                <h3>8. Searching diamonds is easy.</h3>
                                                                {this.getMobileRadio3()}
                                                            </div>
                                                            <div className='page-head mb-75'>
                                                                <h3>
                                                                    9. You are satisfied with the packaging of the
                                                                    product.
                                                                </h3>
                                                                {this.getMobileRadio4()}
                                                            </div>
                                                            <div className='page-head'>
                                                                <h3>10. Payment facility is easy.</h3>
                                                                {this.getMobileRadio5()}
                                                            </div>
                                                        </div>
                                                        {this.getRightRatingPart()}
                                                    </div>
                                                    <div className='container mt-30'>
                                                        <div className='form_blue_btn' style={{ textAlign: 'right' }}>
                                                            <div className='form_blue_btn upload_btn'>
                                                                <button
                                                                    className='button primary-fill'
                                                                    onClick={this.submitForm}
                                                                >
                                                                    <IntlMessages id='app.feedSubmit' />
                                                                </button>
                                                            </div>
                                                            <div className='btn_white'>
                                                                <button
                                                                    className='button secondry-fill'
                                                                    onClick={this.resetForm}
                                                                >
                                                                    <IntlMessages id='app.feedReset' />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!this.props.isForDevice && <FrontFooter />}
                                <Modal
                                    className='no-title-modal'
                                    visible={this.state.success}
                                    centered
                                    title={null}
                                    onCancel={() => this.setState({ success: false })}
                                    footer={null}
                                    width={580}
                                >
                                    <h3 align='center' style={{ color: 'rgba(0,0,0,0.70)' }}>
                                        Thank you for your time and interest in providing us feedback. We appreciate
                                        your feedback.
                                    </h3>
                                    <br />
                                    <div
                                        align='center'
                                        style={{
                                            marginRight: '60px',
                                            marginLeft: '60px'
                                        }}
                                    >
                                        <button
                                            className='modal__btn button primary-fill'
                                            style={{ padding: '5px 15px' }}
                                            onClick={() => this.setState({ success: false })}
                                        >
                                            Ok
                                        </button>
                                    </div>
                                </Modal>
                            </main>
                            <div className='preload' aria-hidden='true' />
                        </div>
                    </div>
                    <div className='page-main page-next' aria-hidden='true' />
                    <div className='page-main page-prev' aria-hidden='true' />
                    <footer className='footer' role='contentinfo' />
                    {/*<SEO url={this.props.location.pathname.split('/')[1]} />*/}
                </div>
            </FrontWrapper>
        )
    }
}

export default Feedback
